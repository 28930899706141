import { Component } from '@angular/core';

@Component({
  selector: 'services-root',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent {
  title = 'SWEIT INFOTECH';
}
