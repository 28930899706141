import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './components/index/index.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ServicesComponent } from './components/services/services.component';
import { TermsComponent } from './components/terms/terms.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
   { path: 'about-us', component: AboutComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'terms-conditions', component: TermsComponent },
  { path: '**', component: IndexComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
