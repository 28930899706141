import { Component } from '@angular/core';

@Component({
  selector: 'index-root',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent {
  title = 'SWEIT INFOTECH';
}
