import { Component } from '@angular/core';

@Component({
  selector: 'privacy-root',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {
  title = 'SWEIT INFOTECH';
}
